<template>
  <div class="live-add">
    <el-card>
      <div slot="header" class="clearfix">
        <span class="title">{{ liveId ? '编辑直播' : '创建直播' }}</span>
      </div>
      <el-form ref="form" :model="form" label-width="130px" :rules="rules">
        <el-form-item label="直播名称" prop="liveName">
          <el-input v-model="form.liveName" placeholder="请输入直播名称" clearable></el-input>
        </el-form-item>
        <el-form-item label="讲师名称" prop="liveUserId">
          <el-select
            v-model="form.liveUserId"
            placeholder="请选择讲师"
            class="input-width"
            clearable
          >
            <el-option
              v-for="item in selectList"
              :key="item.teacherId"
              :value="item.teacherId"
              :label="item.teacherName"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="直播时间" prop="time">
          <el-date-picker
            v-model="form.time"
            type="datetimerange"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            :picker-options="datePickerOptions"
            @blur="pickeTime"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item ref="pic" label="直播封面图" prop="livePic">
          <upload-image
            v-model="form.livePic"
            :upload-img="$refs.livePic"
            :width-size="564"
            :height-size="290"
          ></upload-image>
          <p class="tip">图片大小最好在564 X 290，支持扩展名：.png .jpg .jpeg</p>
        </el-form-item>
        <el-form-item label="直播方式" prop="liveType">
          <el-radio-group v-model="form.liveType">
            <el-radio :label="0">PC</el-radio>
            <!-- <el-radio :label="1">小程序</el-radio> -->
          </el-radio-group>
        </el-form-item>
        <el-form-item ref="tinymceRules" label="直播介绍" prop="liveIntroduce">
          <tinymce v-model="form.liveIntroduce"></tinymce>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" :loading="loading" @click="submit">确定保存</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { createdLive, editSearch, editLive, teacherSelect } from '@/api/live'
import to from 'await-to'
import UploadImage from '@/components/UploadImage'
import Tinymce from '@/components/Tinymce'
export default {
  components: { UploadImage, Tinymce },
  data() {
    var validate = function(rule, value, callback) {
      if (!value || value.length == 0 || value == null || value == undefined) {
        return callback('日期时间不能为空')
      }
      if (Array.isArray(value)) {
        //格式为：daterange、datetimerange检测
        value.map(function(item) {
          if (item === '') {
            return callback('日期时间不能为空')
          }
        })
      } else {
        //格式为：date、datetime、year、month 检测
        if (value === '') {
          return callback('日期时间不能为空')
        }
      }
      return callback()
    }
    return {
      rules: {
        liveName: [{ required: true, message: '请输入直播名称', trigger: 'blur' }],
        liveUserId: [{ required: true, message: '请选择讲师', trigger: 'change' }],
        // livePic: [{ required: true, message: '请上传直播封面图', trigger: 'change' }],
        liveIntroduce: [{ required: true, message: '请输入直播介绍', trigger: 'blur' }],
        time: [{ required: true, validator: validate, trigger: 'change' }],
      },
      form: {
        liveType: 0,
        time: [],
      },
      loading: false,
      liveId: '',
      selectList: [],
      datePickerOptions: {
        disabledDate: time => {
          if (this.liveId) {
            return ''
          } else {
            return time.getTime() < Date.now() - 1 * 3600 * 1000 * 24
          }
        },
      },
    }
  },
  watch: {
    'form.liveIntroduce': function(newValue) {
      if (newValue || newValue !== '' || newValue !== undefined || newValue !== null) {
        this.$refs.tinymceRules.clearValidate()
      }
    },
  },
  mounted() {
    this.liveId = this.$route.params.liveId
    if (this.liveId) this.editSearchData()
  },
  created() {
    this.teacherSelectData()
  },
  methods: {
    pickeTime(time) {
      this.time(time)
    },
    time(time) {
      let time1 = '',
        time2 = ''
      if (time.value) {
        time1 = new Date(time.value[0])
        time2 = new Date(time.value[1])
      } else {
        time1 = new Date(time[0])
        time2 = new Date(time[1])
      }
      var hours = (time2.getTime() - time1.getTime()) / 3600000
      if (hours > 4) {
        this.$message({ type: 'warning', message: '直播时间不能超过4小时' })
        return true
      } else {
        return false
      }
    },
    async teacherSelectData() {
      const [res, err] = await to(teacherSelect())
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.selectList = res.data
    },
    async editSearchData() {
      const [res, err] = await to(editSearch({ liveId: this.liveId }))
      if (err) return this.$message.warning(err.msg)
      this.form = { ...res.data, time: [res.data.startTime, res.data.endTime] }
    },
    submit() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.liveId ? this.update() : this.create()
        }
      })
    },
    filterTeacherName() {
      this.selectList.map(v => {
        if (v.teacherId == this.form.liveUserId) {
          this.form.teacherName = v.teacherName
          return this.form.teacherName
        }
      })
    },
    async create() {
      this.loading = true
      this.form.startTime = this.form.time[0]
      this.form.endTime = this.form.time[1]
      this.filterTeacherName()
      const [, err] = await to(createdLive(this.form))
      this.loading = false
      if (err) return this.$message.error(err.msg)
      this.$message.success('创建成功')
      this.$router.push('/live/liveList')
    },
    async update() {
      if (!this.time(this.form.time)) {
        this.loading = true
        this.form.startTime = this.form.time[0]
        this.form.endTime = this.form.time[1]
        this.filterTeacherName()
        const [, err] = await to(editLive({ ...this.form, liveId: this.liveId }))
        this.loading = false
        if (err) return this.$message.error(err.msg)
        this.$message.success('修改成功')
        this.$router.push('/live/liveList')
      }
    },
  },
}
</script>

<style scoped lang="scss">
.live-add {
  ::v-deep.uploader {
    height: 92px;
    .el-upload {
      width: 180px;
      height: 100%;
      background: #f5f5f5;
      border-radius: 5px;
    }
    .el-upload-list__item {
      width: 180px;
      height: 100%;
    }
  }
}
</style>

<!--
 * @Description: 上传图片组件
 * @Author: xiawenlong
 * @Date: 2020-12-19 14:23:46
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-05-26 10:19:37
-->
<template>
  <div class="upload-image">
    <!-- <el-upload
      class="uploader"
      action=""
      :limit="1"
      :file-list="fileList"
      list-type="picture-card"
      :http-request="uploadRequest"
      :on-remove="handleRemove"
      :before-upload="beforeUpload"
      accept=".jpg,.jpeg,.png,.gif,.svg,.JPG,.JPEG"
    >
      <i class="el-icon-upload"></i>
    </el-upload> -->
    <el-upload
      class="uploader"
      action=""
      :show-file-list="false"
      :http-request="uploadRequest"
      :before-upload="beforeAvatarUpload"
      :accept="filterPicType()"
    >
      <img v-if="image" :src="image" class="avatar" />
      <i v-else class="el-icon-upload"></i>
    </el-upload>
  </div>
</template>
<script>
import { getFileUpload } from '@/api/college'
import to from 'await-to'
export default {
  name: 'UploadImage',
  model: {
    prop: 'value',
    event: 'setModelVal',
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    uploadImg: {
      type: Object,
      default: () => ({}),
    },
    widthSize: {
      type: Number,
      default: 0,
    },
    heightSize: {
      type: Number,
      default: 0,
    },
    isLtPic: {
      type: String,
      default: '',
    },
    picType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      image: '',
      fileList: [],
    }
  },
  watch: {
    value(val) {
      this.image = val
    },
  },
  methods: {
    setVal(val) {
      this.image = val
    },
    async uploadRequest(file) {
      const params = new FormData()
      params.append('fileTypeCode', 'F001')
      params.append('file', file.file)
      params.append('fileName', file.file.name)
      const [res, err] = await to(getFileUpload(params))
      if (err) {
        this.image = ''
        return this.$message.error(err.msg)
      }
      this.$emit('setModelVal', res.data)
      this.uploadImg.clearValidate()
      // this.image = res.data.fileUrl
    },
    beforeAvatarUpload(file) {
      const isImage = file.type.includes('image')
      if (!isImage) {
        this.$message.error('上传文件类型必须是图片!')
      }
      let isLtPicSize = null
      if (this.isLtPic) {
        if (this.isLtPic.indexOf('-') !== -1) {
          let starStr = this.isLtPic.substring(0, this.isLtPic.indexOf('-'))
          let lastStr = this.isLtPic.substring(
            this.isLtPic.lastIndexOf('-') + 1,
            this.isLtPic.length,
          )
          console.log(starStr, lastStr)
          var starStrNum = starStr.match(/\d/g).join('')
          var starStrEnglish = starStr.match(/[a-z]/gi).join('')
          var lastStrNum = lastStr.match(/\d/g).join('')
          var lastStrEnglish = starStr.match(/[a-z]/gi).join('')
          console.log(starStrNum, starStrEnglish)
          isLtPicSize =
            this.isKbOrM(file.size, starStrEnglish, starStrNum) < starStrNum ||
            this.isKbOrM(file.size, lastStrEnglish, lastStrNum) > lastStrNum
          if (!isLtPicSize) {
            this.$message.error(`上传图片大小不能小于${starStr}，不能超过 ${lastStr}!`)
          }
        } else {
          var singleStrNum = this.isLtPic.match(/\d/g).join('')
          var singleStrEnglish = this.isLtPic.match(/[a-z]/gi).join('')
          isLtPicSize = this.isKbOrM(file.size, singleStrEnglish, singleStrNum)
          if (!isLtPicSize) {
            this.$message.error(`上传图片大小不能超过${singleStrNum}${singleStrEnglish}`)
          }
        }
        // isLtPicSize = file.size / 1024 / 20 < 20 || file.size / 1024 / 40 > 40
        // if (!isLtPicSize) {
        //   this.$message.error('上传图片大小不能小于20Kb，不能超过 40Kb!')
        // }
      } else {
        isLtPicSize = file.size / 1024 / 1024 < 5
        if (!isLtPicSize) {
          this.$message.error('上传图片大小不能超过 5MB!')
        }
      }
      if (this.widthSize != 0) {
        const isSize = new Promise((resolve, reject) => {
          let width = this.widthSize
          let height = this.heightSize
          let _URL = window.URL || window.webkitURL
          let img = new Image()
          img.onload = function() {
            let valid = img.width == width && img.height == height
            valid ? resolve() : reject()
          }
          img.src = _URL.createObjectURL(file)
        }).then(
          () => {
            return file
          },
          err => {
            console.log(err)
            this.$message.error(
              '图片大小在' +
                this.widthSize +
                '*' +
                this.heightSize +
                '，支持扩展名：.png .jpg .jpeg',
            )
            return Promise.reject()
          },
        )
        return isImage && isLtPicSize && isSize
      } else {
        return isImage && isLtPicSize
      }
    },
    isKbOrM(size, type, num) {
      if (type == 'kb') {
        //kb-kb
        if (this.isLtPic.indexOf('-')) {
          return size / 1024 / num
        } else {
          return size / 1024 / num < num
        }
      } else {
        //M-M
        if (this.isLtFile.indexOf('-')) {
          return size / 1024 / 1024 / num
        } else {
          return size / 1024 / 1024 / num < num
        }
      }
    },
    filterPicType() {
      if (this.picType) {
        return this.picType
      } else {
        return '.jpg,.jpeg,.png,.gif,.svg,.JPG,.JPEG'
      }
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep.uploader {
  .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    position: relative;
    width: 178px;
    height: type 178px;
    img {
      @extend %width-height;
    }
    .el-icon-upload {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -29px;
      margin-left: -29px;
      font-size: 58px;
    }
    &:hover {
      border-color: #ff7b33;
    }
  }
}
</style>
